<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>菜单管理</p>
			<i>/</i>
			<p @click="jumpPage">菜单列表</p>
			<i>/</i>
			<p class="active">菜单编辑</p>
		</nav>

		<div class="page_edit">

			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">菜单名称</p>
						<el-input class="input_long" v-model.trim="ruleForm.name" maxlength="8"
							placeholder="请输入菜单名称(文本小于8个汉字)"></el-input>
					</el-form-item>
					<el-form-item label="" prop="menu_spell">
						<p class="form_label_p">菜单别称</p>
						<el-input class="input_long" v-model="ruleForm.menu_spell" placeholder="请输入菜单别称(只能是英文或英文+数字)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">菜单级别</p>
						<el-radio-group v-model="ruleForm.menu_rank">
							<el-radio disabled label="1">一级</el-radio>
							<el-radio disabled label="2">二级</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="menu_action">
						<p class="form_label_p">后端路由</p>
						<el-input class="input_long" v-model="ruleForm.menu_action" placeholder="请输入前端路由(只能是英文)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="frontend_route">
						<p class="form_label_p">前端路由</p>
						<el-input class="input_long" v-model="ruleForm.frontend_route" placeholder="请输入前端路由(只能是英文)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="is_show">
						<p class="form_label_p">展示</p>
						<el-radio-group v-model="ruleForm.is_show">
							<el-radio label="1">展示</el-radio>
							<el-radio label="0">不展示</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="is_enable">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.is_enable">
							<el-radio label="1">激活</el-radio>
							<el-radio label="0">冻结</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="sort">
						<p class="form_label_p">排序</p>
						<el-input class="input_long" v-model="ruleForm.sort" placeholder="请输入排序(整数越小越靠前)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">菜单图标class</p>
						<el-input class="input_long" v-model="ruleForm.menu_icon" placeholder="显示菜单左侧icon">
						</el-input>
					</el-form-item>
					<div class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</div>

	</div>
</template>

<script>
	
	export default {
		name: 'menuEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					menu_rank: '', // 菜单级别
					menu_spell: '', //菜单别称
					menu_action: '', // 后端路由不能为空
					frontend_route: '', // 前端路由
					is_show: '1', // 是否展示，默认展示
					is_enable: '1', // 是否激活，默认激活
					sort: '', // 排序
					menu_icon: '', // 菜单图标class
				},
				rules: {
					name: [{
							required: true,
							message: '菜单名称不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[\u4e00-\u9fa5]{0,}$/,
							message: '只能是汉字',
						}
					],
					menu_spell: [{
							required: true,
							message: '别称不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\w+$/,
							message: '简称只能为英文和数字',
						}
					],
					menu_action: [{
						required: true,
						message: '后端路由不能为空',
						trigger: 'blur'
					}, ],
					frontend_route: [{
							required: true,
							message: '前端路由不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\w+$/,
							message: '简称只能为英文和数字',
						}
					],
					sort: [{
						pattern: /^\+?[1-9][0-9]*$/,
						message: '只能是非0的正整数',
					}]
				},
				modifyBtn: true, // 默认创建
			}
		},
		components: {
			
		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;

				this.getMenuInfo();
			}

			// 创建的菜单级别
			if (this.$route.query.parent_id == 0) {
				this.ruleForm.menu_rank = '1';
			} else {
				this.ruleForm.menu_rank = '2';
			}

		},
		methods: {

			// 获取菜单详情做修改
			getMenuInfo() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}menu/info`,
					data: {
						menu_id: this.$route.query.id,
					}
				}).then(res => {
					if (res.code == '0') {
						this.ruleForm.name = res.data.menu_name;
						this.ruleForm.menu_spell = res.data.menu_spell;
						this.ruleForm.menu_rank = res.data.menu_rank;
						this.ruleForm.menu_action = res.data.menu_action;
						this.ruleForm.frontend_route = res.data.frontend_route;
						this.ruleForm.is_show = res.data.is_show;
						this.ruleForm.is_enable = res.data.is_enable;
						this.ruleForm.sort = res.data.sort;
						this.ruleForm.menu_icon = res.data.menu_icon;
					} else {
						this.$message.error(`${res.msg}`);
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}menu/add`,
							data: {
								menu_name: this.ruleForm.name,
								parent_id: this.$route.query.parent_id,
								menu_spell: this.ruleForm.menu_spell,
								menu_rank: this.ruleForm.menu_rank,
								menu_action: this.ruleForm.menu_action,
								frontend_route: this.ruleForm.frontend_route,
								is_show: this.ruleForm.is_show,
								is_enable: this.ruleForm.is_enable,
								sort: this.ruleForm.sort,
								menu_icon: this.ruleForm.menu_icon,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}menu/edit`,
							data: {
								menu_id: this.$route.query.id,
								menu_name: this.ruleForm.name,
								menu_spell: this.ruleForm.menu_spell,
								menu_action: this.ruleForm.menu_action,
								frontend_route: this.ruleForm.frontend_route,
								is_show: this.ruleForm.is_show,
								is_enable: this.ruleForm.is_enable,
								sort: this.ruleForm.sort,
								menu_icon: this.ruleForm.menu_icon,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})



					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
</style>
